import React from "react";
import ReactDOM from "react-dom";
import { createStorefrontApiClient } from "@shopify/storefront-api-client";

import smoothscroll from "smoothscroll-polyfill";
import { ThemeProvider } from "@material-ui/core/styles";
import { ModalProvider } from "./modals/SharedModal/Store";
import { SnackbarProvider } from "./components/Snackbar/Store";
import ErrorBoundary from "./molecules/ErrorBoundary";
import App from "./App";
import theme from "./utils/theme";
import "./index.css";
import "./utils/i18n";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RecalibratePage from "./pages/Recalibrate";

const client = createStorefrontApiClient({
  storeDomain: process.env.REACT_APP_SHOPIFY_DOMAIN,
  apiVersion: "2024-04",
  publicAccessToken: process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN,
});

smoothscroll.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Router>
        <SnackbarProvider>
          <ModalProvider>
            <ThemeProvider theme={theme}>
              <Switch>
                <Route path="/tilpas">
                  <RecalibratePage />
                </Route>
                <Route>
                  <App shopifyClient={client} />
                </Route>
              </Switch>
            </ThemeProvider>
          </ModalProvider>
        </SnackbarProvider>
      </Router>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
