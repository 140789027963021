import { useEffect, useState } from "react";
import { composeGid } from "@shopify/admin-graphql-api-utilities";
import { createStorefrontApiClient } from "@shopify/storefront-api-client";

import { useTranslation } from "react-i18next";

const useCheckoutHandlers = ({
  shopifyClient,
  selectedModelType,
  storedRecipeId,
  handleToggleSummary,
  verifyRecipe,
}) => {
  const [checkout, setCheckout] = useState(null);
  const [currentCheckoutLan, setcurrentCheckoutLan] = useState("da-DA"); //set default check-out language
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language) {
      setcurrentCheckoutLan(i18n.language.toUpperCase());
    }
  }, [i18n.language]);

  const initCheckout = async () => {
    const selectedModelTypeId = selectedModelType?.shopifyId;

    if (!selectedModelTypeId) return;

    const operation = `mutation createCart($cartInput: CartInput,$language: LanguageCode) 
    @inContext(language:$language)
    {
      cartCreate(input: $cartInput) {
        cart {
          id
          checkoutUrl
          lines(first: 10) {
            edges {
              node {
                id
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                  }
                }
              }
            }
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
          }
        }
      }
    }`;
    const { data, errors } = await shopifyClient.request(operation, {
      variables: {
        cartInput: {
          lines: [
            {
              quantity: 1,
              merchandiseId: btoa(
                composeGid("ProductVariant", selectedModelTypeId)
              ),
              attributes: [{ key: "recipeId", value: storedRecipeId }],
            },
          ],
        },
        language: currentCheckoutLan,
      },
    });
    return data.cartCreate.cart.checkoutUrl;
  };

  const handleInitSummary = async (setLoading) => {
    const isVerified = await verifyRecipe(setLoading);

    if (isVerified) {
      handleToggleSummary();
      const url = await initCheckout();
      setCheckoutUrl(url);
    }
  };

  return {
    handleInitSummary,
    checkoutUrl,
  };
};

export default useCheckoutHandlers;
